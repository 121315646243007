export default {
    "loading": "Loading",
    "cancel": "Cancel",
    "error": "An error happened, please contact with the administrator",
    "footer": {
        "app": "Download our app and open your account",
        "aboutUs": "About MyInvestor",
        "legalTerm": "Legal term",
        "help": "Help and contact",
        "news": "New",
        "newClient": "Sign up",
        "knowUs": "Know us",
        "about": "Frequent questions",
        "legal": "Legal",
        "cookiesPolicy": "Cookies policy",
        "info": "Legal info",
        "contact": "We will contact you",
        "blog": "Blog",
        "press": "Press",
        "copyright": "All rights reserved"
    }
};
