import queryString from 'query-string'

const router = {
    push: (router: any) => {
        const { pathname, query, target } = router
        const params = queryString.stringify(query)
        // console.log(['Navigate to', pathname, query, params])
        window.open(`${pathname}${params ? `?${params}` : ''}`, target || '_self')
    }
}

export const route = {
    app: {
        initial: () => `/`,
        accounts: {
            initial: () => `/cuentas-tarjetas/cuentas`,
            junior: () => `/cuentas-tarjetas/cuentas-menores`,
        },
        cards: () => `/cuentas-tarjetas/tarjetas`,
        investment: {
            funds: {
                initial: () => `/inversion/tu-inviertes`,
                indexPortfolio: () => `/inversion/carteras-indexadas`,
                testPortfolio: () => `/inversion/carteras-indexadas/test`,
                indexFunds: () => `/inversion/fondos-indexados`,
                investmentFunds: () => `/inversion/fondos-inversion`,
                fundFinder: () => `/inversion/fondos-inversion/buscador`,
            },
            pensionPlans: {
                initial: (params = '') => `/inversion/planes-pensiones${params}`,
                featured: () => `/inversion/planes-pensiones?anchor=featured-plans`,
                simulator: () => `/inversion/simulador-planes-pensiones`,
                detail: {
                    myinvestorSP500: () => `/inversion/planes-pensiones/myinvestor-indexado-sp500/`,
                    myinvestorGlobal: () => `/inversion/planes-pensiones/myinvestor-indexado-global/`,
                },
            },
            crowdfundingRealState: {
                initial: () => `/inversion/crowdfunding-inmobiliario`,
                detail: () => `/inversion/crowdfunding-inmobiliario/detalle`,
            },
            loans: {
                initial: () => `/inversion/creditos`,
                simulator: () => `/inversion/simulador-credito`,
            },
        },
        mortgage: {
            initial: () => `/hipotecas`,
            fixed: () => `/hipotecas/hipoteca-fija`,
            variable: () => `/hipotecas/hipoteca-variable`,
        },
        knowUs: () => `/conocenos`,
        contact: () => `/contacto`,
        help: {
            frequentQuestions: () => `/ayuda/preguntas-frecuentes`,
        },
        legal: {
            legal: () => `/legal/aviso-legal`,
            info: () => `/legal/informacion-legal`,
            cookies: () => `/legal/politica-cookies`,
        },
        blog: () => `/blog`,
        press: () => `/notas-de-prensa`,
        julia: () => `/julia`,
        simulator: {
            mortgages: {
                initial: () => `/simuladores/hipotecas`,
                new: () => `/simuladores/hipotecas/nueva`,
                subrogation: () => `/simuladores/hipotecas/subrogacion`,
            },
        },
    },
    external: {
        newAccount: () => `https://app.myinvestor.es/#aperturaCuenta:USUARIO_NO_EXISTE/`,
    },
    errors: {
        notFound: () => '/not-found',
    },
};

export default router