// import i18next from 'i18next';
import getSymbolFromCurrency from 'currency-symbol-map';

const i18next = {
    language: 'es-ES'
}

/**
 * Format number using decimal and thousand separator based on selected language
 * @param number
 * @param numberOfDecimals
 */
const formatNumber = (number: number, numberOfDecimals = 0): any => {
    try {
        const lang = i18next.language.split('-')[0];
        const langsCommaAsDecSeparator = ['es'];
        const usesCommaAsDecSeparator = langsCommaAsDecSeparator.includes(lang);
        const decimalSeparator = usesCommaAsDecSeparator ? ',' : '.';
        const thousandSeparator = usesCommaAsDecSeparator ? '.' : ',';

        const decimalCount = Math.abs(numberOfDecimals);
        const absAmount = Math.abs(number);
        const negativeSign = number < 0 ? '-' : '';

        let i = parseInt(absAmount.toFixed(decimalCount)).toString();
        let j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousandSeparator : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSeparator) +
            (decimalCount
                ? decimalSeparator +
                  Math.abs(absAmount - parseInt(i))
                      .toFixed(decimalCount)
                      .slice(2)
                : '')
        );
    } catch (error) {
        return '';
    }
};

/**
 * Format number as amount
 * @param amount
 * @param currency
 * @param numberOfDecimals
 */
const formatAmount = (amount: number, currency = 'EUR', numberOfDecimals = 2): any => {
    const value = formatNumber(amount, numberOfDecimals);
    const symbol = getSymbolFromCurrency(currency);

    const formattedAmount =
        currency === 'USD' ? `${symbol} ${value}` : `${value} ${symbol}`;
    return formattedAmount;
};

/**
 * Format a number as a percentage
 * @param number
 * @param numberOfDecimals
 */
const formatPercentage = (number: number, numberOfDecimals: number = 2) => {
    return `${formatNumber(number, numberOfDecimals)} %`;
};

/**
 * Format phone number
 * @param number
 * @param pattern - regExp with the phone number pattern. @default /^(\d{3})(\d{3})(\d{3})$/
 */
const formatPhone = (number: string, pattern = /^(\d{3})(\d{3})(\d{3})$/) => {
    try {
        // Normalize nunber to let only digits
        const normalized = `${number}`.replace(/\D/g, '');
        const match = normalized.match(pattern);
        if (match) {
            // Calculate number of groups and apply pattern
            const numberOfGroups = `${pattern}`.split('d').length - 1 || 0;
            const phone = [...(Array(numberOfGroups).keys() as any)].reduce(
                (acc, value) => `${value ? `${acc} ` : ''}${match[value + 1]}`,
                ''
            );
            return phone;
        }
        return number;
    } catch (error) {
        return number;
    }
};

/**
 * Clean string symbols and uppercase
 */
const formatString = (string: any) =>
    (string || '')
        .toLowerCase()
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

export {
    formatNumber,
    formatAmount,
    formatPercentage,
    formatPhone,
    formatString,
};
