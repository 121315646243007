import React from 'react';
import classnames from 'classnames';

import './NavButton.scss';

export interface NavButtonProps {
    /**
     *  Active button
     */
    active?: boolean;
    /**
     *  Invert button
     */
    invert?: boolean;
    /**
     * Class name styles
     */
    className?: string;
    /**
     * Style
     */
    style?: any;
    /**
     * Function called on click button
     */
    onClick?(): any;
}

const NavButton: React.FC<NavButtonProps> = ({
    active = false,
    invert = false,
    className,
    style = {},
    onClick = () => {},
    ...props
}) => {
    const classNames = classnames(['nav-button', { active, invert }, className]);
    return (
        <button className={classNames} style={style} onClick={onClick}>
            <span></span>
        </button>
    );
};

export default NavButton;
