import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Config from 'config'
import Info from 'config/info';
import { route } from 'router';
import { Text, Icon } from 'components-guide';
import { formatPhone } from 'utils/format';

import './TheFooter.scss';

const getSelections = (t: any) => [
    {
        id: 'aboutUs',
        text: t('common.footer.aboutUs'),
        items: [
            {
                id: 'newClient',
                href: route.external.newAccount(),
                text: t('common.footer.newClient'),
                'data-trigger': 'Footer_link_HazteCliente',
            },
            { id: 'knowUs', href: route.app.knowUs(), text: t('common.footer.knowUs') },
            { id: 'blog', href: route.app.blog(), text: t('common.footer.blog') },
            { id: 'press', href: route.app.press(), text: t('common.footer.press') },
        ],
    },
    {
        id: 'legalTerm',
        text: t('common.footer.legalTerm'),
        items: [
            { id: 'legal', href: route.app.legal.legal(), text: t('common.footer.legal') },
            { id: 'info', href: route.app.legal.info(), text: t('common.footer.info') },
            { id: 'info', href: route.app.legal.cookies(), text: t('common.footer.cookiesPolicy') },
        ],
    },
    {
        id: 'help',
        text: t('common.footer.help'),
        contact: true,
        items: [{ id: 'about', href: route.app.help.frequentQuestions(), text: t('common.footer.about') }],
    },
];

const RRSS = [
    { id: 'facebook', title: 'Facebook', icon: 'facebook1', href: Info.rrss.facebook },
    { id: 'twitter', title: 'Twitter', icon: 'twitter', href: Info.rrss.twitter },
    { id: 'instagram', title: 'Instagram', icon: 'instagram', href: Info.rrss.instagram },
    { id: 'telegram', title: 'Telegram', icon: 'telegram', href: Info.rrss.telegram },
    { id: 'linkedin', title: 'Linkedin', icon: 'linkedin1', href: Info.rrss.linkedin },
    { id: 'youtube', title: 'Youtube', icon: 'youtube1', href: Info.rrss.youtube },
    { id: 'spotify', title: 'Spotify', icon: 'spotify', href: Info.rrss.spotify },
    { id: 'ivoox', title: 'Ivoox', icon: 'ivoox', href: Info.rrss.ivoox },
];

export interface TheFooterProps {
    /**
     * Translate prop
     */
    t?: any;
    /**
     * Class name styles
     */
    className?: string;
    /**
     * Style
     */
    style?: any;
}

const Section = ({ id, text, contact, items = [] }: any) => (
    <div id={id} className="section">
        <Text weight="bold" className="mb-0">
            {text}
        </Text>
        <ul className={`menu ${!!contact ? 'mb-0' : ''}`}>
            {items.map((item: any, index: any) => (
                <SectionItem key={`section-item-${index}`} {...item} />
            ))}
        </ul>
        {!!contact && <Contact />}
    </div>
);

const SectionItem = ({ id, text, ...props }: any) => (
    <li>
        <a id={id} {...props}>
            {text}
        </a>
    </li>
);

const Rrss = ({ id, icon, href, title = '' }: any) => (
    <li key={id} className="rrss__item">
        <a href={href} target="_blank" title={title} rel="noopener noreferrer">
            <Icon name={icon} size={24} />
        </a>
    </li>
);

const Contact = () => (
    <div className="contact-bar">
        <div className="phone flex row-left-center">
            <Icon name="phone" />
            <a href={`tel:${Info.phones.comercial1}`}>
                <Text typo="small" tag="span" weight="bold">
                    {formatPhone(Info.phones.comercial1)}
                </Text>
            </a>
        </div>
        <a href={`mailto:${Info.emails.info}`} className="flex row-left-center">
            <Icon name="mail" />
            <Text typo="small" tag="span" weight="bold">
                {Info.emails.info}
            </Text>
        </a>
    </div>
);

const TheFooter: React.FC<TheFooterProps> = ({ className, style }) => {
    const { t } = useTranslation();
    const classNames = classnames(['the-footer', className]);
    const sections = getSelections(t);

    return (
        <div className={classNames} style={style}>
            <div className="subfooter">
                <div className="subfooter__wrapper wrapper">
                        {sections.map((section, index) => (
                            <Section key={`section-${index}`} {...(section as any)} />
                        ))}
                        <div className="section--rrss">
                            <ul className="rrss">
                                {RRSS.map((item, index) => (
                                    <Rrss key={`rrss-${index}`} {...item} />
                                ))}
                            </ul>
                        </div>
                </div>
            </div>

            <div className="copyright-bar">
                <div className="wrapper">
                    <Text typo="small" tag="span" color="white">
                        © {Info.data.copyright}. {t('common.footer.copyright')}.
                    </Text>
                </div>
            </div>
        </div>
    );
};

export default TheFooter;
