/* eslint-disable max-len */
const data = {
    copyright: `${new Date().getFullYear()} MyInvestor`,
};

const phones = {
    comercial1: '910005981',
    comercial2: '900800555',
    mortgages: '917453400',
};

const emails = {
    info: 'info@myinvestor.es',
    mortgages: 'dpo@andbank.es',
    support: 'atencionclienteesp@andbank.es',
    securityPolicy: 'dpo@andbank.es',
};

const rrss = {
    facebook: 'https://www.facebook.com/MyInvestorES/',
    twitter: 'https://twitter.com/MyInvestorES',
    youtube: 'https://www.youtube.com/channel/UCc9nsd1tjDdw32wLcM5OOWQ',
    linkedin: 'https://www.linkedin.com/company/myinvestor/',
    instagram: 'https://www.instagram.com/myinvestores',
    telegram: 'https://t.me/myinvestornews',
    spotify:
        'https://open.spotify.com/show/1wtNYaVyWpEWKVPLrtraoZ?si=rWddTTY7Q2ebU6YJbFG9Rw',
    ivoox: 'https://www.ivoox.com/escuchar-myinvestor_nq_742061_1.html',
};

const urls = {
    openAccount:
        'https://app.myinvestor.es/?data_trigger=Header_cta_Apertura&data_traffic_origin=Web_Sala_de_Prensa/#aperturaCuenta:paso1/USUARIO_NO_EXISTE',
    pensionPlan:
        'https://app.myinvestor.es/?&data_trigger=HeroBanner_cta&data_traffic_origin=Web_Inversion_PlanesPensiones/#aperturaCuenta:paso1/USUARIO_NO_EXISTE',
    plusFunds: '',
    indexedPensionPlan:
        'https://app.myinvestor.es/?c_promocion=ppindex#aperturaCuenta:paso1/USUARIO_NO_EXISTE',
    indexedFund: (isin: any) =>
        `https://app.myinvestor.es/#invertir:FONDOS_INDEXADOS/destino-${isin}/`,
    contractPlan: (isin: any) =>
        `https://app.myinvestor.es/?data_trigger=ListadoFondos_cta_contratar_${isin}&data_traffic_origin=Web_Inversion_PlanesPensiones/#aperturaCuenta:paso1/USUARIO_NO_EXISTE`,
    contractFund: (isin: any) =>
        `https://app.myinvestor.es/?data_trigger=ListadoFondos_cta_contratar_${isin}&data_traffic_origin=Web_Inversion_Fondos_FondosIndexados/#aperturaCuenta:paso1/USUARIO_NO_EXISTE`,
    signIn:
        'https://app.myinvestor.es/#login:',
    contractIndexPortfolio:
        'https://app.myinvestor.es/?tipo_nuevo_cliente=carteras_indexadas&__hstc=228649283.f33ee19d7c63a232e4f61dd2baf966b5.1613046642333.1614178216942.1614238254837.20&__hssc=228649283.3.1614238254837&__hsfp=4069100057&data_trigger=InfoCartera_cta_clasica&data_traffic_origin=Web_Inversion_Fondos_CarterasIndexadas/#aperturaCuenta:paso1/USUARIO_NO_EXISTE',
};

const docs = {
    customerPrivacity:
        '/data/pdf/legal/Clausula-Proteccion-de-Datos-Andbank-23_05_2018-CLIENTES.pdf',
    leadPrivacity:
        '/data/pdf/legal/Clausula-Proteccion-de-Datos-Andbank-23_05_2018-POTENCIALES.pdf',
    cookies:
        'https://myinvestor.es/politica-de-cookies/',
};

const logos = {
    desktop: {
        default: '/images/logo-myinvestor.svg',
        invert: '/images/logo-myinvestor-invert.svg',
    },
    mobile: {
        default: '/images/imagotipo-myinvestor.svg',
        invert: '/images/imagotipo-myinvestor-invert.svg',
    },
};

const token =
    'a2e8e18ad26a079c576038f0ad4fa18ce0d9e415f5bf6f43f89cf3831a0e4685__';

export default {
    data,
    docs,
    phones,
    emails,
    rrss,
    urls,
    logos,
    token,
};
