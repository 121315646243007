import React from 'react';
import classnames from 'classnames';
import { Text, Link } from 'components-guide';

import './NavSignIn.scss';

export interface NavSignInProps {
    /**
     *  Invert
     */
    invert?: boolean;
    /**
     * Translate prop
     */
    t: any;
    /**
     * Class name styles
     */
    className?: string;
    /**
     * Style
     */
    style?: any;
    /**
     * Function called on click button
     */
    onClick?(): any;
}

const NavSignIn: React.FC<NavSignInProps> = ({
    invert = false,
    t,
    className,
    style = {},
    onClick = () => {},
    ...props
}) => {
    const classNames = classnames(['nav-signin', { invert }, className]);
    return (
        <Link
            iconLeft="icon-cuenta-individual"
            underline
            tabIndex={0}
            className={classNames}
            style={style}
            onClick={onClick}
        >
            <Text tag="span" weight="bold">
                {t('navigation.signIn')}
            </Text>
        </Link>
    );
};

export default NavSignIn;
