import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios'
import { NavDesktop, NavMobile, NavButton, NavSignIn } from './components';
import Router from 'router'
import Info from 'config/info';
import Config from 'config'

export interface TheNavProps {
    /**
     * Invert color
     */
    invert?: boolean;
    /**
     * Translate prop
     */
    t: any;
    /**
     * Class name styles
     */
    className?: string;
    /**
     * Style
     */
    style?: any;
}

const TheNav: React.FC<TheNavProps> = ({ invert = false, t, className, style }) => {
    const [mobile, setMobile] = useState(false);
    const [links, setLinks] = useState<Array<any>>([])
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

    useEffect(() => {
        const fetch = async () => {
            const { data } = await axios.get(Config.api.baseURL + '/api/menu/');
            const links = data?.data || [];
            setLinks(links);
        };

        fetch();
    }, []);

    const handleToggle = () => setMobile(!mobile);
    const handleOnClickItem = ({ href, params }: any) => {
        if (isMobile) setMobile(!mobile);
        if (href) Router.push({ pathname: href, query: params });
    };
    const handleOnClickSignIn = () => Router.push({ pathname: Info.urls.signIn });
    const handleOnClickSignUp = () => Router.push({ pathname: Info.urls.openAccount });

    const common = {
        links,
        t,
        onClickItem: handleOnClickItem,
        onClickSignIn: handleOnClickSignIn,
        onClickSignUp: handleOnClickSignUp,
    };

    return (
        <>
            <NavDesktop invert={invert} {...common} />
            {isMobile ? <NavSignIn invert={invert} t={t} onClick={handleOnClickSignIn} /> : null}
            <NavMobile active={mobile && isMobile} {...common} />
            <NavButton invert={invert} active={mobile} onClick={handleToggle} />
        </>
    );
};

export default TheNav;
