import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import locales from './locales';

i18next
    // Detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // Pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // Init i18next
    // For all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'es',
        debug: process.env.NODE_ENV === 'development',
        react: {
            useSuspense: false,
        },
        resources: {
            // en: {
            //     translation: locales.en,
            // },
            es: {
                translation: locales.es,
            },
        },
    });

export default i18next;
