export default {
    "accountsAndCards": "Accounts and cards",
    "accounts": "Accounts",
    "paidAccount": "Account 1 %",
    "juniorAccount": "Junior Account",
    "cards": "Cards",
    "creditCard": "Credit card",
    "debitCard": "Debit card",
    "investment": "Investment",
    "weInvest": "We invest",
    "youInvest": "You invest",
    "investmentFund": "Investment funds",
    "myinvestorValue": "MyInvestor Value",
    "permanentFund": "MyInvestor Cartera Permanente",
    "myinvestorNasdaq": "MyInvestor Nasdaq 100",
    "myinvestorSP500": "MyInvestor S&P500 Equiponderado",
    "myinvestorPonderado": "MyInvestor Ponderado Economía Mundial",
    "indexPortfolio": "Index portfolio",
    "indexFunds": "Index dunds",
    "investmentFunds": "Investment funds",
    "pensionPlans": "Pension plans",
    "pensionPlansSP500": "MyInvestor Indexado S&P500",
    "indexGlobalPlan": "MyInvestor Indexado Global",
    "crowdfundingRealState": "Crowdfunding Real State",
    "crowdfundingRealStateProject": "Projecto",
    "mortgage": "Mortgage",
    "fixedMortgage": "Fixed mortgage",
    "variableMortgage": "Variable-Rate mortgage",
    "myInvestorMortgage": "Hipotecas Sin Mochila",
    "tools": "Tools",
    "portfolioSimulator": "Portfolio simulator",
    "fundFinderShort": "Fund Finder",
    "fundFinder": "Fund Finder",
    "pensionSimulator": "Pension plan simulator",
    "pensionPlanFinder": "Pension plan finder",
    "pensionPlansSimulator": "Pension plan simulator",
    "newMortgageSimulator": "New mortgage simulator",
    "oldMortgageSimulator": "Old mortgage simulator",
    "loansSimulator": "Simulador de créditos de inversión",
    "signUp": "Sign up",
    "signIn": "Sign in",
    "insurance": "Insurance",
    "faqs": "Frequently Asked Questions",
    "detail": "Detail",

    "featured": {
        "loans": {
            "title": "Crédito inversión y consumo",
            "description": "Saca más rendimiento a tu dinero"
        }
    },
    "allProducts": "Todos los productos"
};
