const config = {
    project: {
        name: process.env.REACT_APP_PROJECT_NAME || '',
        description: process.env.REACT_APP_PROJECT_DESCRIPTION || '',
    },
    isDev: process.env.NODE_ENV === 'development',
    isTest: process.env.NODE_ENV === 'test',
    useMock: process.env.REACT_APP_API_MOCK === 'true',
    api: {
        baseURL: process.env.REACT_APP_API_BASE_URL || '',
        timeout: 10000,
    },
    auth: {
        dev: {
            username: process.env.REACT_APP_AUTH_DEV_USER || '',
            password: process.env.REACT_APP_AUTH_DEV_PASS || '',
        },
    },
    storage: {
        key: process.env.REACT_APP_PERSIST_KEY,
        storage: 'local',
    },
    themePath: (window as any)?.['template_uri'] ? `${(window as any)?.['template_uri']}/components/public` : ''
};

export default config;
