const getStorage = (type = 'local') =>
    type === 'local' ? localStorage : sessionStorage;

export const get = ({ type, key }: any) => {
    if (typeof window === 'undefined') return;
    const storage = getStorage(type);
    const value = JSON.parse(storage.getItem(key) || '{}') || {};
    return Object.keys(value).length ? value : undefined;
};

export const set = ({ type, key, value }: any) => {
    if (typeof window === 'undefined') return;
    const storage = getStorage(type);
    storage.setItem(key, JSON.stringify(value));
};

export const push = ({ type, key, value }: any) => {
    if (typeof window === 'undefined') return;
    const storage = getStorage(type);
    const data = get({ type, key }) || {};
    storage.setItem(key, JSON.stringify({ ...data, ...value }));
};

export const local = {
    get,
    set,
    push,
};

export const session = {
    get: (params: any) => get({ type: 'session', ...params }),
    set: (params: any) => set({ type: 'session', ...params }),
    push: (params: any) => push({ type: 'session', ...params }),
};

export const keys = {
    config: 'myinvestor:config',
    cookies: 'myinvestor:cookies',
};

export default {
    get,
    set,
    local,
    session,
    keys,
};
