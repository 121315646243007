export default {
    "loading": "Cargando",
    "cancel": "Cancelar",
    "error": "Ocurrió un error, por favor contacta con el administrador",
    "footer": {
        "app": "Descárgate nuestra app y abre tu cuenta",
        "aboutUs": "Sobre MyInvestor",
        "legalTerm": "Términos legales",
        "help": "Ayuda y contacto",
        "news": "Noticias",
        "newClient": "Hazte cliente",
        "knowUs": "Conócenos",
        "about": "Preguntas frecuentes",
        "legal": "Aviso legal",
        "cookiesPolicy": "Política de cookies",
        "info": "Información legal",
        "contact": "Nosotros te contactamos",
        "blog": "Blog",
        "press": "Sala de prensa",
        "copyright": "Todos los derechos reservados"
    }
};
