import React from 'react';
import classnames from 'classnames';

import './Picture.scss';

export interface PictureProps {
    /**
     * Path or URL to image
     */
    src: string;
    /**
     * alt text for in image
     */
    alt?: any;
    /**
     * Width image
     */
    width?: string | number;
    /**
     * Height image
     */
    height?: string | number;
    /**
     * Class name styles
     */
    className?: string;
    /**
     * Style
     */
    style?: any;
}

const Picture: React.FC<PictureProps> = ({ src = '', alt = '', width, height, className, style }) => {
    const classNames = classnames(['picture', className]);

    return <img className={classNames} style={style} src={src} alt={alt} width={width} height={height} />;
};

export default Picture;
