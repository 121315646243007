export default {
    "accountsAndCards": "Cuentas y tarjetas",
    "accounts": "Cuentas",
    "paidAccount": "Cuenta 1\u00a0%",
    "juniorAccount": "Cuenta Junior",
    "cards": "Tarjetas",
    "creditCard": "Tarjeta de crédito",
    "debitCard": "Tarjeta de débito",
    "investment": "Inversión",
    "weInvest": "Invertimos por ti",
    "youInvest": "Tú inviertes",
    "investmentFund": "Fondos de inversión",
    "indexPortfolio": "Carteras indexadas",
    "indexFunds": "Fondos indexados",
    "investmentFunds": "Fondos de inversión",
    "myinvestorValue": "MyInvestor Value",
    "permanentFund": "MyInvestor Cartera Permanente",
    "myinvestorNasdaq": "MyInvestor Nasdaq 100",
    "myinvestorSP500": "MyInvestor S&P500 Equiponderado",
    "myinvestorPonderado": "MyInvestor Ponderado Economía Mundial",
    "pensionPlans": "Planes de pensiones",
    "pensionPlansSP500": "MyInvestor Indexado S&P500",
    "indexGlobalPlan": "MyInvestor Indexado Global",
    "crowdfundingRealState": "Crowdfunding Urbanitae",
    "crowdfundingRealStateProject": "Proyecto",
    "mortgage": "Hipotecas",
    "fixedMortgage": "Hipoteca fija",
    "variableMortgage": "Hipoteca variable",
    "myInvestorMortgage": "Hipotecas Sin Mochila",
    "tools": "Herramientas",
    "portfolioSimulator": "Simulador de carteras",
    "fundFinderShort": "Buscador de fondos",
    "fundFinder": "Buscador de fondos de inversión",
    "pensionSimulator": "Simulador de planes de pensiones",
    "pensionPlanFinder": "Buscador de planes de pensiones",
    "pensionPlansSimulator": "Simulador de planes de pensiones",
    "newMortgageSimulator": "Simulador Contrata tu hipoteca",
    "oldMortgageSimulator": "Simulador Trae tu hipoteca",
    "loansSimulator": "Simulador de créditos de inversión",
    "signUp": "Hazte cliente",
    "signIn": "Inicia sesión",
    "insurance": "Seguros",
    "faqs": "Preguntas frecuentes",
    "detail": "Detalle",

    "featured": {
        "loans": {
            "title": "Crédito inversión y consumo",
            "description": "Saca más rendimiento a tu dinero"
        }
    },
    "allProducts": "Todos los productos"
};
