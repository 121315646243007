import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { useScroll } from 'hooks';
import { TheNav, Picture } from 'components';
import Config from 'config'
import Info from 'config/info';

import './TheHeader.scss';

export interface TheHeaderProps {
    /**
     * Header animated
     * @default false
     */
    animated?: boolean;
    /**
     * Class name styles
     */
    className?: string;
    /**
     * Style
     */
    style?: any;
    /**
     * Translate prop
     */
    t?: any;
}

const getLogo = ({ invert, isMobile }: any) => {
    const type = isMobile ? 'mobile' : 'desktop';
    return invert ? Info.logos[type].invert : Info.logos[type].default;
};

const TheHeader: React.FC<TheHeaderProps> = ({ animated = false, className, style }) => {
    const { t } = useTranslation();
    const { y } = useScroll();
    const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
    const [invert, setInvert] = useState(animated);
    const [logo, setLogo] = useState('');
    const classNames = classnames(['the-header', { animated: invert }, className]);

    useEffect(() => {
        setLogo(getLogo({ invert, isMobile }));
    }, [invert, isMobile]);
    useEffect(() => setInvert(animated && y <= 1), [animated, y]);

    return (
        <header className={classNames} style={style}>
            {invert ? (
                <svg width="485px" height="72px" viewBox="0 0 485 72" version="1.1" className="the-header__path">
                    <title>mascarahome2_mobile</title>
                    <g id="mascarahome2_mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <path
                            d="M-3.63797881e-12,67.0375048 C199.319681,82.6638752 384.330094,60.4350461 485,13.7368383 L485,13.7368383 L485,72 L-3.63797881e-12,72 Z"
                            fill="#FFFFFF"
                        />
                    </g>
                </svg>
            ) : null}
            <div className="the-header__wrapper">
                {logo ? (
                    <>
                        <div className="brand">
                            <a href="/" tabIndex={0}>
                                <Picture src={`${Config.themePath}${logo}`} alt="MyInvestor" className="logo" />
                            </a>
                        </div>
                        <TheNav invert={invert} t={t} />
                    </>
                ) : null}
            </div>
        </header>
    );
};

export default TheHeader;
