import React from 'react';
import classnames from 'classnames';
import { Button, Link, Text, Icon } from 'components-guide';
import { Trans } from 'react-i18next';

import './NavDesktop.scss';

export interface LinksProps {
    /**
     * id link
     */
    id?: string;
    /**
     * href
     */
    href?: string;
    /**
     * query params
     */
    params?: any;
    /**
     * text
     */
    text?: string;
    /**
     * Links of nav
     */
    children?: Array<LinksProps>;
}

export interface NavDesktopProps {
    /**
     * Links of nav
     */
    links?: Array<LinksProps>;
    /**
     * Invert color
     */
    invert?: boolean;
    /**
     * Translate prop
     */
    t?: any;
    /**
     * Class name styles
     */
    className?: string;
    /**
     * Style
     */
    style?: any;
    /**
     * Function called on click item
     */
    onClickItem?(item?: any): any;
    /**
     * Function called on click sign in button
     */
    onClickSignIn?(): any;
    /**
     * Function called on click sign up button
     */
    onClickSignUp?(): any;
}

const SubMenuLink = ({ id, href, text, icon, description }: any) => (
    <li className="submenu-link">
        <Link href={href}>
            <Text className="section-title" weight="bold">
                {text}
            </Text>
            <Text className="section-description" typo="small">
                <Trans>
                    {description}
                    <Text weight="bold" color="secondary" typo="small" tag="span"></Text>
                </Trans>
            </Text>
        </Link>
    </li>
);

const SubMenuItem = ({ id, href, params, text, children, onClick }: any) => (
    <li className="submenu-item flex-1">
        <div className="submenu-item__title">
            {href ? (
                <Link href={href} iconSize={24} iconRight="chevron-right" className="flex row-left-center">
                    <Text tag="span" weight="bold">
                        {text}
                    </Text>
                </Link>
            ) : (
                <Text tag="span" weight="bold">
                    {text}
                </Text>
            )}
        </div>
        {(children || []).length ? (
            <ul>
                {children.map((item: any, index: any) => (
                    <SubMenuLink key={`submenu-link-${index}`} {...item} onClick={onClick} />
                ))}
            </ul>
        ) : null}
    </li>
);

const Featured = ({ items, onClick = () => {} }: any) => (
    <ul className="submenu__featured">
        {(items || []).length
            ? items.map((item: any, index: number) => {
                  const { text, description, icon, href } = item;
                  return (
                      <li key={`featured-${index}`}>
                          {href ? (
                              <Link href={href} className="flex row-left-center">
                                  {icon ? <Icon name={icon} size={32} className="mr-1" /> : null}
                                  <div className="flex col-center-left">
                                      <Text tag="span" typo="small" weight="bold">
                                          {text}
                                      </Text>
                                      <Text tag="span" typo="small">
                                          {description}
                                      </Text>
                                  </div>
                              </Link>
                          ) : (
                              <Text tag="span" weight="bold">
                                  {text}
                              </Text>
                          )}
                      </li>
                  );
              })
            : null}
    </ul>
);

const SubMenu = ({ id, featured, items, className, onClick }: any) => {
    const classNames = classnames(['submenu', { [`submenu-${id}`]: id }, className]);
    return (
        <div className={classNames}>
            <ul>
                {items.map((item: any, index: number) => (
                    !item.onlyMobile ? <SubMenuItem key={`submenu-item-${index}`} {...item} onClick={onClick} /> : null
                ))}
            </ul>
            {(featured || []).length ? <Featured items={featured} onClick={onClick} /> : null}
        </div>
    );
};

const MenuItem = ({ id, href, params, text, featured, children, invert, onClick }: any) => (
    <li key={id} className="menu-item">
        <Link className={`${invert ? `invert` : ''}`} onClick={() => onClick({ href, params })}>
            <Text tag="span" weight="bold" color={invert ? 'white' : 'black'}>
                {text}
            </Text>
        </Link>
        {(children || []).length ? <SubMenu id={id} featured={featured} items={children} onClick={onClick} /> : null}
    </li>
);

const Menu = ({ items, invert, onClick }: any) => (
    <ul className="menu">
        {items.map((item: any, index: any) => (
            <MenuItem key={`menu-${index}`} {...item} invert={invert} onClick={onClick} />
        ))}
    </ul>
);

const SignButtons = ({ color, onClickSignIn, onClickSignUp, t }: any) => (
    <div className="nav-desktop__cta">
        <Button
            outline
            color={color}
            tabIndex={0}
            className="nav-desktop__button"
            data-cy="cta-header-signin-click"
            onClick={onClickSignIn}
        >
            {t('navigation.signIn')}
        </Button>
        <Button
            color={color}
            tabIndex={0}
            className="nav-desktop__button"
            data-cy="cta-header-signup-click"
            onClick={onClickSignUp}
        >
            {t('navigation.signUp')}
        </Button>
    </div>
);

const NavDesktop: React.FC<NavDesktopProps> = ({
    links = [],
    invert = false,
    t,
    className,
    style,
    onClickItem = () => {},
    onClickSignIn = () => {},
    onClickSignUp = () => {},
}) => {
    const classNames = classnames(['nav-desktop', className]);
    const color = invert ? 'white' : undefined;

    return (
        <nav className={classNames} style={style}>
            {!!links.length && <Menu items={links} invert={invert} onClick={onClickItem} />}
            <SignButtons color={color} onClickSignIn={onClickSignIn} onClickSignUp={onClickSignUp} t={t} />
        </nav>
    );
};

export default NavDesktop;
