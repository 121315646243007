import React, { useState, useEffect } from 'react';
import { PlayState, Timeline, Tween } from 'react-gsap';
import classnames from 'classnames';
import { Button, Link, Text } from 'components-guide';
import Info from 'config/info';

import './NavMobile.scss';

export interface LinksProps {
    /**
     * id link
     */
    id?: string;
    /**
     * href
     */
    href?: string;
    /**
     * query params
     */
    params?: any;
    /**
     * text
     */
    text?: string;
    /**
     * Links of nav
     */
    children?: Array<LinksProps>;
}

export interface NavMobileProps {
    /**
     * Ref
     */
    ref?: any;
    /**
     * Links of nav
     */
    links: Array<LinksProps>;
    /**
     *  Active button
     */
    active?: boolean;
    /**
     *  Invert button
     */
    invert?: boolean;
    /**
     * Translate prop
     */
    t: any;
    /**
     * Class name styles
     */
    className?: string;
    /**
     * Style
     */
    style?: any;
    /**
     * Function called on click item
     */
    onClickItem?(item?: any): any;
    /**
     * Function called on click sign in button
     */
    onClickSignIn?(): any;
    /**
     * Function called on click sign up button
     */
    onClickSignUp?(): any;
}

const SubListItem = (item: { text: any; onClick: any; }) => {
    const { text, onClick } = item;
    return (
        <li className="submenu-link">
            <Link className="flex row-left-center" onClick={() => onClick(item)}>
                <Text tag="span" color="primary">
                    {text}
                </Text>
            </Link>
        </li>
    );
};

const ListItem = ({ item, hideChildren, onClick }: any) => {
    const { href, text, children = [] } = item;
    return (
        <li className="list-item">
            {href || (children.length && hideChildren) ? (
                <Link
                    iconSize={24}
                    iconRight="chevron-right"
                    className="flex row-left-center"
                    onClick={() => onClick(item)}
                >
                    <Text typo="h5" tag="span" weight="semi" className="mb-0">
                        {text}
                    </Text>
                </Link>
            ) : (
                <Text typo="h5" tag="span" weight="semi" className="mb-0">
                    {text}
                </Text>
            )}
            {children.length && !hideChildren ? (
                <ul className="submenu">
                    {children.map((item: any, index: any) => (
                        <SubListItem key={`submenu-link-${index}`} {...item} onClick={onClick} />
                    ))}
                </ul>
            ) : null}
        </li>
    );
};

const List = ({ items, hideChildren = false, onClick }: any) => (
    <ul className="list">
        {items.map((item: any, index: any) => (
            <ListItem key={`list-item-${index}`} hideChildren={hideChildren} item={item} onClick={onClick} />
        ))}
    </ul>
);

const Featured = ({ items, onClick = () => {} }: any) => (
    <ul className="list--featured">
        {(items || []).length
            ? items.map((item: any, index: number) => {
                  const { text, description, href, params } = item;
                  return (
                      <li key={`featured-${index}`}>
                          {href ? (
                              <Link className="flex row-left-center" onClick={() => onClick({ href, params })}>
                                  <div className="flex col-center-left">
                                      <Text tag="span" color="primary">
                                          {text}
                                      </Text>
                                      <Text tag="span">{description}</Text>
                                  </div>
                              </Link>
                          ) : (
                              <Text tag="span" weight="bold">
                                  {text}
                              </Text>
                          )}
                      </li>
                  );
              })
            : null}
    </ul>
);

const Section = ({ text, featured = [], children = [], onClick, onBack }: any) => (
    <>
        <div className="nav-mobile__section-header">
            <Link iconLeft="chevron-left" iconSize={24} className="flex row-left-center" onClick={onBack}>
                <Text tag="span" weight="semi">
                    {text}
                </Text>
            </Link>
        </div>
        {children.length ? <List items={children} onClick={onClick} /> : null}
        {featured.length ? <Featured items={featured} onClick={onClick} /> : null}
    </>
);

const Content = ({
    active,
    links = [],
    t,
    onClickItem = () => {},
    onClickSignIn = () => {},
    onClickSignUp = () => {},
}: any) => {
    const [selected, setSelected] = useState(null);
    const cleanSelected = () => setSelected(null);

    useEffect(() => {
        if (!active) setTimeout(cleanSelected, 1500);
    }, [active]);

    const handleOnClickGroup = (item: React.SetStateAction<any>) => {
        if (item?.children) setSelected(item);
        else onClickItem(item);
    };

    return (
        <div className="nav-mobile__wrapper">
            <section className="nav-mobile__section">
                <div className="nav-mobile__section-header">
                    <a href="/">
                        <img src={Info.logos.mobile.default} alt="MyInvestor" width="53" height="32" />
                    </a>
                </div>
                <List items={links} hideChildren onClick={handleOnClickGroup} />
            </section>
            <section className={`nav-mobile__section ${selected ? 'selected' : ''}`}>
                <Section {...((selected as any) || {})} onClick={onClickItem} onBack={cleanSelected} />
            </section>
            <div className="nav-mobile__footer flex row-center">
                <Button outline onClick={onClickSignIn}>
                    {t('navigation.signIn')}
                </Button>
                <Button onClick={onClickSignUp}>{t('navigation.signUp')}</Button>
            </div>
        </div>
    );
};

const NavMobile: React.FC<NavMobileProps> = React.forwardRef(
    ({ active = false, className, style = {}, ...props }, ref) => {
        const classNames = classnames(['nav-mobile', { active }, className]);

        return (
            <Timeline
                paused
                playState={active ? PlayState.play : PlayState.reverse}
                target={
                    <div className={classNames} style={style}>
                        <Content active={active} {...props} />
                    </div>
                }
            >
                <Tween from={{ autoAlpha: 0 }} to={{ autoAlpha: 1 }} duration={0.15} />
            </Timeline>
        );
    }
);

export default NavMobile;
