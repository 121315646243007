/**
 * Id services
 */
const IDS = {
    GOOGLE_ANALYTICS: 'UA-74637762-2',
    GOOGLE_TAG_MANAGER: 'GTM-KRGCRPR',
    GOOGLE_OPTIMIZE: 'GTM-5K3Z6XM',
    HOTJAR: 1938183,
};

/**
 * Check if script with specific id already exists in the page
 * @param id
 */
const existTag = (id: string) => !!document.getElementById(id);

/**
 * Initialize external cookies
 * @param analytics - Analytics options of localStorage
 */
const initialize = ({ analytics }: any) => {
    if (analytics) {
        // if (!existTag('googleAnalytics')) googleAnalytics({ analytics });
        if (!existTag('googleTagManager')) googleTagManager({ analytics });
        // if (!existTag('googleOptimize')) googleOptimize({ analytics });
        if (!existTag('hotjar')) hotjar({ analytics });
    }
};

export const googleAnalytics = ({ analytics }: any) => {
    if (analytics) {
        (function (d) {
            const script = d.createElement('script');
            script.id = 'googleAnalytics';
            script.type = 'text/javascript';
            script.async = true;
            script.onload = function () {
                // remote script has loaded
            };
            script.src = `https://www.googletagmanager.com/gtag/js?id=${IDS.GOOGLE_ANALYTICS}`;
            d.getElementsByTagName('head')[0].appendChild(script);

            (window as any).dataLayer = (window as any).dataLayer || [];
            function gtag(key: any, value: any) {
                ((window as any).dataLayer || []).push(arguments);
            }
            gtag('js', new Date());
            gtag('config', IDS.GOOGLE_ANALYTICS);
        })(document);
    }
};

export const googleTagManager = ({ analytics }: any) => {
    if (analytics) {
        (function (w: any, d, s, l, i) {
            // eslint-disable-next-line no-param-reassign
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f: any = d.getElementsByTagName(s)[0],
                j: any = d.createElement(s),
                dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.id = 'googleTagManager';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
            if (f.parentNode) f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', IDS.GOOGLE_TAG_MANAGER);
    }
};

export const googleOptimize = ({ analytics }: any) => {
    if (analytics) {
        (function (d) {
            const script = d.createElement('script');
            script.id = 'googleOptimize';
            script.type = 'text/javascript';
            script.async = true;
            script.onload = function () {
                // remote script has loaded
            };
            script.src = `https://www.googleoptimize.com/optimize.js?id=${IDS.GOOGLE_OPTIMIZE}`;
            d.getElementsByTagName('head')[0].appendChild(script);
        })(document);
    }
};

export const hotjar = ({ analytics }: any) => {
    if (analytics) {
        (function (h: any, o, t, j, a: any = {}, r: any = {}) {
            // eslint-disable-next-line no-param-reassign
            h['hj'] =
                h['hj'] ||
                function () {
                    // eslint-disable-next-line no-param-reassign
                    (h['hj'].q = h['hj'].q || []).push(arguments);
                };
            // eslint-disable-next-line no-param-reassign
            h['_hjSettings'] = { hjid: IDS.HOTJAR, hjsv: 6 };
            // eslint-disable-next-line no-param-reassign
            a = o.getElementsByTagName('head')[0];
            // eslint-disable-next-line no-param-reassign
            r = o.createElement('script');
            // eslint-disable-next-line no-param-reassign
            r.async = 1;
            // eslint-disable-next-line no-param-reassign
            r.src = t + h['_hjSettings'].hjid + j + h['_hjSettings'].hjsv;
            a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
};

export default {
    initialize,
};
