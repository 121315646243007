import React from 'react';
import ReactDOM from 'react-dom';
import { TheHeader, TheFooter, Cookies } from 'components'
import reportWebVitals from './reportWebVitals';
import Config from 'config'
import 'lang/i18n';

import 'components-guide/dist/index.css';
import 'components-guide/dist/theme/index.css';
import 'theme/styles.scss';

const elements = {
  ...(Config.isDev ? {
    header: 'tmp-header',
    footer: 'tmp-footer',
    cookies: 'tmp-cookies'
  }: {
    header: 'site-header',
    footer: 'site-footer',
    cookies: 'cookiesContainer'
  })
}

// Header
if (document.getElementById(elements.header)) {
  ReactDOM.render(
    <React.StrictMode>
    <TheHeader />
  </React.StrictMode>,
  document.getElementById(elements.header)
  );
}

// Footer
if (document.getElementById(elements.footer)) {
  ReactDOM.render(
    <React.StrictMode>
      <TheFooter />
    </React.StrictMode>,
    document.getElementById(elements.footer)
  );
}

// Cookies
if (document.getElementById(elements.cookies)) {
  ReactDOM.render(
      <React.StrictMode>
          <Cookies />
      </React.StrictMode>,
      document.getElementById(elements.cookies)
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
