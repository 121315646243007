import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import router, { route } from 'router';
import { useTranslation, Trans } from 'react-i18next';
import { Modal, Text, Button, Link } from 'components-guide';
import Storage from 'services/storage.service';
import CookiesService from 'services/cookies.service';

import './Cookies.scss';

export interface CookiesProps {
    /**
     * Class name styles
     */
    className?: string;
    /**
     * Style
     */
    style?: any;
}

const COOKIES = { technical: true, personalization: true, publicity: true, analytics: true };

const Cookies: React.FC<CookiesProps> = ({ className, style }) => {
    const { t } = useTranslation();
    const [cookies, setCookies] = useState(false);
    const classNames = classnames(['cookies', className]);

    const getCookies = () => Storage.get({ key: Storage.keys.cookies });

    useEffect(() => {
        const cookies = getCookies()
        setCookies(!cookies);
        CookiesService.initialize(cookies || {});
    }, []);

    const handleOnClickConfig = () => router.push({ pathname: route.app.legal.cookies() });
    const handleOnClickAccept = () => {
        setCookies(false);
        CookiesService.initialize(COOKIES);
        Storage.set({ key: Storage.keys.cookies, value: COOKIES });
    };

    return (
        <div className={classNames} style={style}>
            <Modal opened={cookies}>
                <div className="cookies__wrapper screen__wrapper">
                    <div className="cookies__text flex-1">
                        <Text weight="bold">{t('cookies.title')}</Text>
                        <Text typo="small-xs" className="mb-0">
                            <Trans>
                                {t('cookies.description')}
                                <Link onClick={handleOnClickConfig}></Link>
                            </Trans>
                        </Text>
                    </div>
                    <div className="cookies__actions">
                        <Link underline color="primary" className="mr-3" onClick={handleOnClickConfig}>
                            {t('cookies.btnConfig')}
                        </Link>
                        <Button onClick={handleOnClickAccept}>{t('cookies.btnAccept')}</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Cookies;
